import { ProjectService } from '@/api/services/project.service'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useIntl } from 'react-intl'

export const useUserLocation = () => {
  const { workspaceId, projectId } = useProject()
  const { setZoomSeat } = useMapStore()
  const { setActiveLayer } = useGlobalStore()
  const intl = useIntl()
  const { enqueueToast } = useToast()

  const setUserLocation = async (search: string) => {
    const res = await ProjectService.searchV2({
      layer: false,
      point: false,
      user: true,
      projectId,
      workspaceId,
      term: search,
    })

    if (!res.data?.search_result) {
      enqueueToast(
        {
          title: intl.formatMessage({ id: 'error' }),
          message: intl.formatMessage({ id: 'error-user-not-have-location' }),
        },
        { variant: 'error' },
      )
      return
    }

    const result = res.data.search_result[0]

    type Location = {
      place_id: string
      layer_id: string
    }

    const locationParentObject = Object.entries(result).find(
      ([_, value]) => typeof value === 'object' && (value as any)?.locations,
    )?.[1] as {
      category: string[]
      locations: Location[]
    }
    const location = locationParentObject.locations[0]

    if (!location) {
      enqueueToast(
        {
          title: intl.formatMessage({ id: 'error' }),
          message: intl.formatMessage({ id: 'error-user-not-have-location' }),
        },
        { variant: 'error' },
      )
      return
    }

    const placeId = Number(location.place_id) || null
    const layerId = Number(location.layer_id) || null

    if (!placeId || !layerId) {
      enqueueToast(
        {
          title: intl.formatMessage({ id: 'error' }),
          message: intl.formatMessage({ id: 'error-user-location' }),
        },
        { variant: 'error' },
      )
      return
    }

    setActiveLayer(layerId)
    setTimeout(() => {
      setZoomSeat(placeId)
    }, 500)
  }

  return {
    setUserLocation,
  }
}
