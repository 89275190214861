import React, { useMemo } from 'react'
import { Grid } from '@/components/Grid'
import viewImage from '@/images/view.png'
import { useGlobalStore } from '@/stores/globalStore'
import InfoIcon from '../../icons/InfoIcon'
import useResponsive from '@/hooks/useResponsive'
import MobileEmployeeItem from '../MobileEmployeeItem'
import { formatPhoneNumber } from '@/utils/helpers/phone.helpers'
import PointIcon from '../../icons/PointIcon'
import { useMapStore } from '@/stores/mapStore'
import { useToast } from '../../shared/toast/useToast'
import { useIntl } from 'react-intl'
import { useUserLocation } from './use-user-location'

export const EmployeeMobileListItem: React.FC<any> = ({ user }) => {
  const { setEmployee } = useGlobalStore()
  const { id, display, fields } = user

  const openUser = () => setEmployee(id)

  return (
    <MobileEmployeeItem
      name={display}
      fields={fields}
      openUser={openUser}
    />
  )
}

const EmployeeListItem: React.FC<any> = ({ template, columns, item }) => {
  const { setUserLocation } = useUserLocation()
  const { setEmployee } = useGlobalStore()
  const { id, data } = item

  /**
   * Получить строку, по которой будет выполняться поиск пользователя.
   * Формируется большая строка, чтобы результат поиска с большей вероятностью содержал одного пользователя
   */
  const searchString = useMemo(() => {
    const name = data.find(column => column.alias === 'display')?.value
    const post = data.find(column => column.alias === 'title')?.value
    const phoneNumber = data.find(
      columns => columns.alias === 'telephonenumber',
    )?.value
    const searchString = [name, phoneNumber, post].filter(Boolean).join(' ')

    return searchString
  }, [data])

  const openUser = async () => {
    await setUserLocation(searchString)
    setEmployee(id)
  }

  return (
    <Grid.Row $cols={template}>
      {data.map(({ label, value, type }, idx) => (
        <Grid.Item key={idx}>
          {type === 'phone' ? formatPhoneNumber(value) : value}
        </Grid.Item>
      ))}
      <Grid.Item>
        <a
          href="#"
          onClick={openUser}
        >
          <PointIcon />
        </a>
      </Grid.Item>
      {/* <Grid.Item>
        <a
          href="#"
          onClick={openUser}
        >
          <InfoIcon />
        </a>
      </Grid.Item> */}
    </Grid.Row>
  )
}

export default EmployeeListItem
