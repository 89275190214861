import { MetadataNode } from '@/api/services/project.service'
import { useNavigationStore } from '@/components/layout/route-navigator/navigation.store'
import { useMemo } from 'react'

const useGraph = ({ points, fields }) => {
  if (!points || !fields) return {}

  const fieldIds = fields.map(f => f.id)
  const graphNodes = points.reduce(
    (acc, p) => {
      const pointFields = p['fields'] || {}
      const pointersEntry: any =
        Object.entries(pointFields).find(([key, value]) => {
          const id = parseInt(key.split('_')[1])

          if (!id) return null
          if (!fieldIds.includes(id)) return null

          return value
        }) || []

      const poitnersValue = pointersEntry[1] || ''
      const pointers = poitnersValue
        .split(',')
        .filter(v => v)
        .map(p => Number(p))

      return {
        ...acc,
        [p.name]: pointers,
      }
    },
    {} as Record<string, string>,
  )

  return graphNodes
}

export const useRoute = ({ navigation, metadata, points }) => {
  const navigationRoute = useNavigationStore(state => state.route)
  const { from, to } = navigationRoute

  console.log(navigationRoute)

  const route = useMemo(() => {
    if (!from || !from.x || !from.y || !to || !to.x || !to.y) return []

    const navigaiontTypes = navigation.keypoint.map(k => k.name)
    const navigaiontFields = navigation.keypoint.reduce(
      (acc, k) => {
        return {
          ...acc,
          [k.name]: k.field,
        }
      },
      {} as Record<string, string>,
    )
    const filteredPoints = points.filter(point =>
      navigaiontTypes.includes(point.type_name),
    )

    const fields =
      metadata?.nodes
        .filter(type => navigaiontTypes.includes(type.name))
        .map(type => getTypeFields(type, navigaiontFields))
        .flat() || []

    const filledPoints = filteredPoints.map(p => {
      const data: any[] = Object.values(p['plugin_data'])
      return {
        ...p,
        fields: mergeObjects(data),
      }
    })

    const graph = useGraph({ points: filledPoints, fields })

    const nearestPointToStart = findNearestPoint(filledPoints, from.x, from.y)
    const nearestPointToEnd = findNearestPoint(filledPoints, to.x, to.y)
    const route = findRoute(graph, nearestPointToStart, nearestPointToEnd)

    return route
      ? route
          .map(p => {
            const mapPoint = points.find(pt => pt.name == p)
            return mapPoint && mapPoint.x && mapPoint.y
              ? [mapPoint.x, mapPoint.y]
              : null
          })
          .filter(v => v) || []
      : []
  }, [from, to, navigation, metadata, points])

  if (!from || !from.x || !from.y || !to || !to.x || !to.y) return []
  return [
    ...(from && from.x && from.y ? [[from.x, from.y]] : []),
    ...route,
    ...(to && to.x && to.y ? [[to.x, to.y]] : []),
  ]
}

export const getTypeFields = (type: MetadataNode, navigaiontFields) => {
  const plugins = type?.plugin_data

  if (!plugins) return false
  const fields = Object.values(plugins).find(v => (v as any)['fields'])?.[
    'fields'
  ]

  if (!fields) return false
  const fieldName = navigaiontFields[type.name]

  return fields.find(f => f.name == fieldName)
}

const mergeObjects = (array: Record<string, any>[]): Record<string, any> => {
  return array.reduce((result, obj) => {
    for (const key in obj) {
      result[key] = obj[key]
    }
    return result
  }, {})
}

function findRoute(graph, start, end, visited = new Set()) {
  if (!start || !end) return null
  // Добавляем текущую точку в посещенные
  visited.add(start)

  // Если текущая точка равна конечной, возвращаем массив с этой точкой
  if (start === end) {
    return [start]
  }

  // Перебираем все точки, в которые можно перейти из текущей
  if (!graph[start]) return null
  for (const next of graph[start]) {
    // Если следующая точка еще не посещена, ищем маршрут из нее
    if (!visited.has(next)) {
      console.log(next, end, visited)
      const route = findRoute(graph, next, end, visited)
      // Если маршрут найден, добавляем текущую точку в начало массива и возвращаем его
      if (route) {
        route.unshift(start)
        return route
      }
    }
  }

  // Если маршрут не найден, возвращаем null
  return null
}

interface Point {
  x: number
  y: number
}

function findNearestPoint(
  points: Point[],
  x: number,
  y: number,
): number | null {
  if (points.length === 0 || !x || !y) {
    return null // Если массив точек пуст, вернем null
  }

  let nearestPoint = points[0] // Предположим, что первая точка - ближайшая

  // Вычислим расстояние от заданных координат до первой точки
  let minDistance = Math.sqrt(
    Math.pow(x - nearestPoint.x, 2) + Math.pow(y - nearestPoint.y, 2),
  )

  // Пройдемся по остальным точкам
  for (let i = 1; i < points.length; i++) {
    const currentPoint = points[i]
    // Вычислим расстояние от заданных координат до текущей точки
    const distance = Math.sqrt(
      Math.pow(x - currentPoint.x, 2) + Math.pow(y - currentPoint.y, 2),
    )
    // Если расстояние до текущей точки меньше, чем до предыдущей ближайшей точки,
    // то обновим ближайшую точку и значение минимального расстояния
    if (distance < minDistance) {
      nearestPoint = currentPoint
      minDistance = distance
    }
  }

  return Number(nearestPoint['name'])
}
